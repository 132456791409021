import { format, parse } from "date-fns";
import * as config from "../Config";
import { ProdottoFinito } from "../models/ProdottoFinito";
import ProdottoFinitoDTO from "../models/ProdottoFinitoDTO";

export const getData = async (
	from?: Date,
	to?: Date
): Promise<Array<ProdottoFinito>> => {
	from = from ?? new Date(2000, 1);
	to = to ?? new Date();

	const dateFormat = "yyyyMMdd";
	const response = await fetch(
		`${config.dataURL}?datada=${format(from, dateFormat)}&dataa=${format(
			to,
			dateFormat
		)}`
	);

	const pfs = (await response.json()) as Array<ProdottoFinitoDTO>;

	const tmp = pfs.map((pf) => {
		if (pf.TS_modifica) {
			console.log(
				pf.TS_modifica,
				parse(pf.TS_modifica, "dd/MM/yyyy HH:mm:ss", new Date())
			);
		} else {
			console.log("Sono stronzo");
		}
		return {
			...pf,
			TS_modifica:
				pf.TS_modifica != null
					? parse(pf.TS_modifica, "dd/MM/yyyy HH:mm:ss", new Date())
					: null,
		};
	});

	return tmp;
};
