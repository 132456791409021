import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { AG_GRID_LOCALE_IT } from "./ag-grid.locale-it";
import "./App.css";
import { ProdottoFinito } from "./models/ProdottoFinito";
import { getData } from "./services/LampIdService";

function App() {
	const [prodottiFiniti, setProdottiFiniti] = useState<Array<ProdottoFinito>>(
		[]
	);
	useEffect(() => {
		const a = async () => {
			const pfs = await getData();
			setProdottiFiniti(pfs);
		};
		a();
	}, []);

	return (
		<>
			<div className="header">
				<div className="header-logo">
					<img src="logo.jpg" style={{ height: "100%" }} />
				</div>
				{/* <h1 className="header-title">Movimentazione</h1> */}
				<div style={{ flex: 1 }}></div>
			</div>
			<div className="content">
				<div className="ag-theme-alpine content-table">
					<AgGridReact
						rowData={prodottiFiniti}
						reactUi
						defaultColDef={{
							flex: 1,
							minWidth: 200,
							resizable: true,
							floatingFilter: true,
							floatingFilterComponentParams: {
								suppressFilterButton: true,
							},
							filter: "agTextColumnFilter",
							filterParams: {
								defaultOption: "startsWith",
							},
							suppressMenu: true,
							sortable: true,
							sortingOrder: ["asc", "desc"],
							cellStyle: { borderRight: "1px solid #dde2eb" },
						}}
						localeText={AG_GRID_LOCALE_IT}
						pagination
					>
						<AgGridColumn
							field="LottoFinito"
							headerName="Lotto"
						></AgGridColumn>
						<AgGridColumn
							field="Bancaleinput"
							headerName="Bancale"
							maxWidth={100}
							cellStyle={{
								textAlign: "center",
							}}
						></AgGridColumn>
						<AgGridColumn field="CodiceProdotto"></AgGridColumn>
						<AgGridColumn field="DescrizioneProdotto"></AgGridColumn>
						<AgGridColumn
							field="DescrizionePosizione"
							headerName="Posizione"
							filter={true}
							floatingFilterComponentParams={{
								suppressFilterButton: false,
							}}
						></AgGridColumn>
						<AgGridColumn
							field="TS_modifica"
							headerName="Ultima modifica"
							sort="desc"
							filter={false}
							valueFormatter={(d) => {
								return d.value
									? format(
											d.value as Date,
											"yyyy-MM-dd HH:mm:ss"
									  )
									: "";
							}}
						></AgGridColumn>
					</AgGridReact>
				</div>
			</div>
		</>
	);
}

export default App;
