export const AG_GRID_LOCALE_IT = {
	// Set Filter
	selectAll: "(Seleziona tutto)",
	selectAllSearchResults: "(Seleziona tutti i risultati della ricerca)",
	searchOoo: "Cerca...",
	blanks: "(Vuoto)",
	noMatches: "nessun match",

	// Number Filter & Text Filter
	filterOoo: "Filtra...",
	equals: "Uguale",
	notEqual: "Non uguale",
	empty: "Scegli uno",

	// Number Filter
	lessThan: "Meno di",
	greaterThan: "Più di",
	lessThanOrEqual: "Meno o uguale di",
	greaterThanOrEqual: "Più o uguale di",
	inRange: "Nel range",
	inRangeStart: "a",
	inRangeEnd: "da",

	// Text Filter
	contains: "Contiene",
	notContains: "Non contiene",
	startsWith: "Inizia con",
	endsWith: "Finisce con",

	// Date Filter
	dateFormatOoo: "yyyy-mm-dd",

	// Filter Conditions
	andCondition: "E",
	orCondition: "O",

	// Filter Buttons
	applyFilter: "Applica",
	resetFilter: "Reset",
	clearFilter: "Pulisci",
	cancelFilter: "Cancella",

	// Filter Titles
	textFilter: "Filtro Testo",
	numberFilter: "Filtro Numeri",
	dateFilter: "Filtro Date",
	setFilter: "Applica Filtro",

	// Side Bar
	columns: "Colonne",
	filters: "Filtri",

	// columns tool panel
	// pivotMode: 'Pivot Mode',
	// groups: 'Row Groups',
	// rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
	// values: 'Values',
	// valueColumnsEmptyMessage: 'Drag here to aggregate',
	// pivots: 'Column Labels',
	// pivotColumnsEmptyMessage: 'Drag here to set column labels',

	// Header of the Default Group Column
	group: "Gruppo",

	// Other
	loadingOoo: "Caricamento...",
	noRowsToShow: "Nessun dato da mostrate",
	enabled: "Abilitata",

	// Menu
	// pinColumn: 'Pin Column',
	// pinLeft: 'Pin Left',
	// pinRight: 'Pin Right',
	// noPin: 'No Pin',
	// valueAggregation: 'Value Aggregation',
	// autosizeThiscolumn: 'Autosize This Column',
	// autosizeAllColumns: 'Autosize All Columns',
	// groupBy: 'Group by',
	// ungroupBy: 'Un-Group by',
	// resetColumns: 'Reset Columns',
	// expandAll: 'Expand All',
	// collapseAll: 'Close All',
	copy: "Copia",
	// ctrlC: 'Ctrl+C',
	copyWithHeaders: "Copia con i titoli",
	paste: "Incolla",
	// ctrlV: 'Ctrl+V',
	export: "Esporta",
	csvExport: "Esporta CSV",
	excelExport: "Esporta Excel (.xlsx)",
	// excelXmlExport: 'Excel Export (.xml)',

	// Enterprise Menu Aggregation and Status Bar
	sum: "Somma",
	min: "Minimo",
	max: "Massimo",
	none: "Nessuna",
	count: "Contatore",
	avg: "Media",
	filteredRows: "Filtrate",
	selectedRows: "Selezionate",
	totalRows: "Righe Totali",
	totalAndFilteredRows: "Righe",
	more: "Più",
	to: "-",
	of: "di",
	page: "Pagina",
	nextPage: "Pagina Successiva",
	lastPage: "Ultima Pagina",
	firstPage: "Prima Pagina",
	previousPage: "Pagina Precedente",

	// Enterprise Menu (Charts)
	// pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
	// pivotChart: 'Pivot Chart',
	// chartRange: 'Chart Range',

	// columnChart: 'Column',
	// groupedColumn: 'Grouped',
	// stackedColumn: 'Stacked',
	// normalizedColumn: '100% Stacked',

	// barChart: 'Bar',
	// groupedBar: 'Grouped',
	// stackedBar: 'Stacked',
	// normalizedBar: '100% Stacked',

	// pieChart: 'Pie',
	// pie: 'Pie',
	// doughnut: 'Doughnut',

	// line: 'Line',

	// xyChart: 'X Y (Scatter)',
	// scatter: 'Scatter',
	// bubble: 'Bubble',

	// areaChart: 'Area',
	// area: 'Area',
	// stackedArea: 'Stacked',
	// normalizedArea: '100% Stacked',

	// histogramChart: 'Histogram',

	// Charts
	// pivotChartTitle: 'Pivot Chart',
	// rangeChartTitle: 'Range Chart',
	// settings: 'Settings',
	// data: 'Data',
	// format: 'Format',
	// categories: 'Categories',
	// defaultCategory: '(None)',
	// series: 'Series',
	// xyValues: 'X Y Values',
	// paired: 'Paired Mode',
	// axis: 'Axis',
	// navigator: 'Navigator',
	// color: 'Color',
	// thickness: 'Thickness',
	// xType: 'X Type',
	// automatic: 'Automatic',
	// category: 'Category',
	// number: 'Number',
	// time: 'Time',
	// xRotation: 'X Rotation',
	// yRotation: 'Y Rotation',
	// ticks: 'Ticks',
	// width: 'Width',
	// height: 'Height',
	// length: 'Length',
	// padding: 'Padding',
	// spacing: 'Spacing',
	// chart: 'Chart',
	// title: 'Title',
	// titlePlaceholder: 'Chart title - double click to edit',
	// background: 'Background',
	// font: 'Font',
	// top: 'Top',
	// right: 'Right',
	// bottom: 'Bottom',
	// left: 'Left',
	// labels: 'Labels',
	// size: 'Size',
	// minSize: 'Minimum Size',
	// maxSize: 'Maximum Size',
	// legend: 'Legend',
	// position: 'Position',
	// markerSize: 'Marker Size',
	// markerStroke: 'Marker Stroke',
	// markerPadding: 'Marker Padding',
	// itemSpacing: 'Item Spacing',
	// itemPaddingX: 'Item Padding X',
	// itemPaddingY: 'Item Padding Y',
	// layoutHorizontalSpacing: 'Horizontal Spacing',
	// layoutVerticalSpacing: 'Vertical Spacing',
	// strokeWidth: 'Stroke Width',
	// offset: 'Offset',
	// offsets: 'Offsets',
	// tooltips: 'Tooltips',
	// callout: 'Callout',
	// markers: 'Markers',
	// shadow: 'Shadow',
	// blur: 'Blur',
	// xOffset: 'X Offset',
	// yOffset: 'Y Offset',
	// lineWidth: 'Line Width',
	// normal: 'Normal',
	// bold: 'Bold',
	// italic: 'Italic',
	// boldItalic: 'Bold Italic',
	// predefined: 'Predefined',
	// fillOpacity: 'Fill Opacity',
	// strokeOpacity: 'Line Opacity',
	// histogramBinCount: 'Bin count',
	// columnGroup: 'Column',
	// barGroup: 'Bar',
	// pieGroup: 'Pie',
	// lineGroup: 'Line',
	// scatterGroup: 'X Y (Scatter)',
	// areaGroup: 'Area',
	// histogramGroup: 'Histogram',
	// groupedColumnTooltip: 'Grouped',
	// stackedColumnTooltip: 'Stacked',
	// normalizedColumnTooltip: '100% Stacked',
	// groupedBarTooltip: 'Grouped',
	// stackedBarTooltip: 'Stacked',
	// normalizedBarTooltip: '100% Stacked',
	// pieTooltip: 'Pie',
	// doughnutTooltip: 'Doughnut',
	// lineTooltip: 'Line',
	// groupedAreaTooltip: 'Area',
	// stackedAreaTooltip: 'Stacked',
	// normalizedAreaTooltip: '100% Stacked',
	// scatterTooltip: 'Scatter',
	// bubbleTooltip: 'Bubble',
	// histogramTooltip: 'Histogram',
	// noDataToChart: 'No data available to be charted.',
	// pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.'
};
